import { Form } from 'shared/dc-form';
import { SPINNER_SHOW_CLASS } from 'shared/spinner';
import { notificationService } from 'project/services';
import { notificationTypes } from 'project/general';

export class BaseForm extends Form {
    _afterSubmit() {
        this.hideButtonSpinner();
        super._afterSubmit();
    }

    _beforeSubmit() {
        this.showButtonSpinner();
        super._beforeSubmit();
    }

    showButtonSpinner() {
        if (this.submitElement) {
            this.submitElement.classList.add(SPINNER_SHOW_CLASS);
        }
    }

    hideButtonSpinner() {
        if (this.submitElement) {
            this.submitElement.classList.remove(SPINNER_SHOW_CLASS);
        }
    }

    _handleErrorSubmit(error) {
        super._handleErrorSubmit(error);
        const message = typeof error.toString === 'function' ? error.toString() : error;
        notificationService.push({
            message,
            type: notificationTypes.ERROR,
        });
    }

    _handleSuccessSubmit(response) {
        if (response.data && response.data.redirectUrl) {
            if (this.submitElement) {
                this.submitElement.disabled = true;
            }
            this.showButtonSpinner();
        }
        super._handleSuccessSubmit(response);
    }
}
