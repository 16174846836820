import React from 'react';
import PropTypes from 'prop-types';
import { generateBemCss } from 'project/helpers';

export class OrderSummary extends React.Component {
    renderLine(line, bemList) {
        if (!line) return null;

        const lineCss = generateBemCss('order-summary__line', bemList);

        return (
            <div className={lineCss} key={line.title}>
                <div>
                    <div className="order-summary__title">{line.title}</div>
                    {line.description &&
                        <div className="order-summary__vat" dangerouslySetInnerHTML={{
                            __html: line.description
                        }}>
                        </div>
                    }
                </div>
                {line.amount &&
                    <div className="order-summary__value" dangerouslySetInnerHTML={{
                        __html: line.amount.formatted
                    }}>
                    </div>
                }

            </div>
        );
    }

    render() {
        const { summary } = this.props;

        if (!this.props.summary) return null;

        return (
            <div className="order-summary">

                {summary.itemDiscounts.map(item => this.renderLine(item))}

                {this.renderLine(summary.subtotal)}
                {
                    summary.isDeliverable && summary.delivery &&
                    this.renderLine(summary.delivery)
                }
                {
                    summary.isDeliverable && !summary.delivery &&
                    <p className="order-summary__note">{summary.noShippingOptionMessage}</p>
                }
                {summary.orderDiscounts.map(item => this.renderLine(item))}
                {this.renderLine(summary.total, ['calculated'])}
            </div>
        );
    }
}

OrderSummary.propTypes = {
    summary: PropTypes.object,
};
