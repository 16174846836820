import React from 'react';
import PropTypes from 'prop-types';
import { generateBemCss } from 'project/helpers';

const MemberDiscount = ({ model }) => {
    if (!model) return null;

    const { originalPrice, newPrice, hasNewPrice } = model;
    const newPriceMod = hasNewPrice ? 'has-new' : '';
    const rootCss = generateBemCss('member-discount', [newPriceMod]);

    return (
        <div className={rootCss}>
            {
                originalPrice &&
                <span className="member-discount__orig" dangerouslySetInnerHTML={{
                    __html: originalPrice.formatted
                }}></span>
            }
            {
                hasNewPrice && newPrice &&
                <span className="member-discount__new" dangerouslySetInnerHTML={{
                    __html: newPrice.formatted
                }}></span>
            }
        </div>
    );
};

MemberDiscount.propTypes = {
    model: PropTypes.shape({
        price: PropTypes.shape({
            value: PropTypes.number,
            formatted: PropTypes.string,
        }),
        originalPrice: PropTypes.shape({
            value: PropTypes.number,
            formatted: PropTypes.string,
        }),
        hasNewPrice: PropTypes.bool
    })
};

export { MemberDiscount };
