import { app } from 'general';

export const getApiUrlByName = (name) => {
    const apis = app.getConfig('api');

    if (!Array.isArray(apis)) return null;

    const foundApi = apis.find((api) => api.name === name);

    return foundApi ? foundApi.path : null;
};

export const detectIE = () => window.navigator.userAgent.match(/(MSIE|Trident)/);
