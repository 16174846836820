import React from 'react';
import PropTypes from 'prop-types';
import { SpriteSvg } from 'shared/sprite-svg';

class Pagination extends React.Component {
    renderPage(page) {
        if (page.isPrevPage) {
            return (
                <button
                    className="pagination__prev"
                    type="button"
                    disabled={page.isDisabled}
                    onClick={() => {
                        this.props.onSelectPage(page.number);
                    }}
                    aria-label="go to previous page"
                >
                    <SpriteSvg iconName="chevron-left-heavy" width="7" height="12" />
                </button>
            );
        }

        if (page.isNextPage) {
            return (
                <button
                    className="pagination__next"
                    type="button"
                    disabled={page.isDisabled}
                    onClick={() => {
                        this.props.onSelectPage(page.number);
                    }}
                    aria-label="go to next page"
                ><SpriteSvg iconName="chevron-right-heavy" width="7" height="12" /></button>
            );
        }

        if (page.isPrevGroup || page.isNextGroup) {
            return (
                <button
                    className={`pagination__dots ${pageStateCss}`}
                    type="button"
                    aria-hidden={true}
                    disabled
                >...</button>
            );
        }

        const pageStateCss = page.isActive ? 'is-current' : '';

        return (
            <button
                className={`pagination__page ${pageStateCss}`}
                type="button"
                disabled={page.isDisabled}
                onClick={() => this.props.onSelectPage(page.number)}
                aria-label={`go to page number ${page.number}`}
            >{page.number}</button>
        );
    }

    render() {
        const { pager } = this.props;

        if (!pager || !Array.isArray(pager.pages)) return null;

        return (
            <div className="pagination">
                <ul className="pagination__list">
                    {
                        pager.pages.map((page, i) => {
                            return (
                                <li className="pagination__li" key={i}>
                                    {this.renderPage(page)}
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }
}

Pagination.defaultProps = {
    onSelectPage: () => {},
};

Pagination.propTypes = {
    pager: PropTypes.object,
    onSelectPage: PropTypes.func,
};

export default Pagination;
