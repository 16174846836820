import { BaseComponent } from 'project/general';

class QuantityComponent extends BaseComponent {
    static getNamespace() {
        return 'quantity';
    }

    static getRequiredRefs() {
        return ['btnMinus', 'input', 'btnPlus'];
    }

    onInit() {
        this.addListener(this.refs.btnPlus, 'click', this.onBtnPlus);
        this.addListener(this.refs.btnMinus, 'click', this.onBtnMinus);
        this.addListener(this.refs.input, 'input', this.onChangeInput);

        this.state = {
            min: Number(this.refs.input.getAttribute('min')) || 0,
            max: Number(this.refs.input.getAttribute('max')) || 2147483647,
        };

        this.checkInput();
    }

    checkInput = () => {
        if (this.refs.input.classList.contains('input-validation-error')) {
            this.refs.btnPlus.classList.add('invalid');
            this.refs.btnMinus.classList.add('invalid');
        }
    };

    onBtnPlus = () => {
        if (Number(this.refs.input.value) >= this.state.max) return;

        this.refs.input.value++;
    };

    onBtnMinus = () => {
        if (Number(this.refs.input.value <= this.state.min)) return;

        this.refs.input.value--;
    };

    onChangeInput = () => {
        if (!this.isInputValid()) {
            this.refs.input.value = this.state.min;
            return;
        }

        if (this.refs.input.value <= this.state.min) {
            this.refs.input.value = this.state.min;
        }

        if (this.refs.input.value > this.state.max) {
            this.refs.input.value = this.state.max;
        }
    };

    getCurrent = () => {
        return Number(this.refs.input.value);
    };

    enable = () => {
        this.element.classList.remove('is-disabled');
        this.refs.input.disabled = false;
        this.refs.btnMinus.disabled = false;
        this.refs.btnPlus.disabled = false;
    };

    disable = () => {
        this.element.classList.add('is-disabled');
        this.refs.input.disabled = true;
        this.refs.btnMinus.disabled = true;
        this.refs.btnPlus.disabled = true;
    };

    setMax = (value) => {
        if (typeof value !== 'number') return;

        this.state.max = value;
    };

    isInputValid() {
        return /[0-9]|\./.test(this.refs.input.value);
    }
}

export default QuantityComponent;
