import { Observable } from 'foundation/observable';

const MAX_COUNT = 5;

class NotificationService {
    constructor() {
        this.state = {};
        this.count = 0;

        this.observeable = new Observable();
    }

    push = (notification) => {
        if (notification === undefined || notification.message === undefined) {
            console.warn(`Bad notification format ${notification}`);
            return;
        }
        const id = this.count++;
        this.state[id] = {
            ...notification,
            id
        };

        this._cutOutExtra();

        this.observeable.notify(this.state);
    };

    remove = (id) => {
        delete this.state[id];
        this.observeable.notify(this.state);
    };

    subscribeOnUpdate = (cb) => {
        return this.observeable.subscribe(cb);
    };

    getState() {
        return this.state;
    }

    _cutOutExtra() {
        const keys = Object.keys(this.state);
        const countToRemove = keys.length - MAX_COUNT;
        if (countToRemove > 0) {
            keys.sort((a, b) => a - b);
            for (let i = 0; i < countToRemove; i++) {
                delete this.state[keys[i]];
            }
        }
    }
}

export const notificationService = new NotificationService();
