
const EVENT_FIELD_VALIDATION_FAILED = 'field-validation-failed';
const EVENT_FORM_VALIDATION_FAILED = 'form-validation-failed';
const ENCTYPE_URLENCODED = 'application/x-www-form-urlencoded';
const ENCTYPE_MULTIPART = 'multipart/form-data';
const ENCTYPE_JSON = 'json';
const VALIDATE_ALWAYS_ATTRIBUTE = 'data-validate-always';

export default {
    EVENT_FIELD_VALIDATION_FAILED,
    EVENT_FORM_VALIDATION_FAILED,
    ENCTYPE_URLENCODED,
    ENCTYPE_MULTIPART,
    ENCTYPE_JSON,
    VALIDATE_ALWAYS_ATTRIBUTE,
};
