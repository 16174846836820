import { ArrayHelper } from 'foundation/helpers';
import { app } from 'general';
import GtmService from './gtm';


export class EcommerceTracking {
    static trackAddToBasket(order, basket) {
        const product = basket.basketItems[ArrayHelper.findIndexByProp(basket.basketItems, 'id', order.currentVariantId)];
        const trackingData = EcommerceTracking._getTrackingDataFromProduct(product);
        EcommerceTracking.trackAddProduct(trackingData, order.quantity);
    }

    static trackRemoveFromBasket(id, basketBeforeRemove) {
        const product = basketBeforeRemove.basketItems[ArrayHelper.findIndexByProp(basketBeforeRemove.basketItems, 'id', id)];
        const trackingData = EcommerceTracking._getTrackingDataFromProduct(product);
        EcommerceTracking.trackRemoveProduct(trackingData, product.quantity);
    }

    static trackUpdateQuantity({ productId, quantity }, basketBeforeUpdate) {
        const product = basketBeforeUpdate.basketItems[ArrayHelper.findIndexByProp(basketBeforeUpdate.basketItems, 'id', productId)];
        const trackingData = EcommerceTracking._getTrackingDataFromProduct(product);
        const dif = quantity - product.quantity;
        EcommerceTracking[dif > 0 ? 'trackAddProduct' : 'trackRemoveProduct'](trackingData, Math.abs(dif));
    }


    static trackCheckout(actionField, trackingItems) {
        const data = {
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField,
                    products: trackingItems
                }
            }
        };
        GtmService.pushData(data);
    }

    static trackPurchase(actionField, trackingItems) {
        const data = {
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField,
                    products: trackingItems
                }
            }
        };
        GtmService.pushData(data);
    }

    static trackImpression(trackingProducts) {
        const data = {
            event: 'productimpression',
            ecommerce: {
                currencyCode: app.getConfig('currencyCode'),
                impressions: trackingProducts
            }
        };

        GtmService.pushData(data);
    }

    static trackRemoveProduct(trackingData, quantity) {
        const data = {
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [{
                        ...trackingData,
                        quantity: quantity
                    }]
                }
            }
        };
        GtmService.pushData(data);
    }

    static trackAddProduct(trackingData, quantity) {
        const data = {
            event: 'addToCart',
            ecommerce: {
                currencyCode: app.getConfig('currencyCode'),
                add: {
                    products: [{
                        ...trackingData,
                        quantity: quantity
                    }]
                }
            }
        };
        GtmService.pushData(data);
    }

    static _getTrackingDataFromProduct(product) {
        return product.trackingBasketItem || product.trackingProduct
    }
}
