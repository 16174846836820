import { isElement } from 'dom-helper';
import { LocateService } from './loqate-service';

const COUNTRY_DROPDOWN_SELECTOR = 'select[data-loqate-field=CountryName]';
class LoqateForm {
    constructor(rootNode, options) {
        if (!isElement(rootNode)) {
            console.error(`${rootNode} should be a DOM Element`);
        }
        this.rootNode = rootNode;
        this.options = { ...LoqateForm.defaultOptions, ...options };
        this.fieldsObj = null;
        this.loqateControl = null;
        this.inpits = [];
        this.countryDropdown = null;
        this.init();
    }

    static get defaultOptions() {
        return {
        };
    }

    init() {
        this.countryDropdown = this.getCountryDropdown();
        this.fieldsObj = this.getFieldsObj();
        this.inputs = this.getInputs();
        if (this.options.loqateFieldsConfig === undefined && Object.keys(this.fieldsObj).length === 0) {
            return;
        }
        this.disableAutocomplete();
        LocateService.loadResources().then(() => {
            const fields = this.getLoqateFieldsConfig();
            this.loqateControl = LocateService.createLoqateControl(fields);
            this.loqateControl.listen('populate', (loqateAddress) => {
                this.updateCountryDropdown(loqateAddress.CountryIso2);
            });
            this.loqateControl.listen('prepopulate', (loqateAddress) => {
                LocateService.updatePreserved(this.inputs, fields, loqateAddress);
            });

            LocateService.disableAutocomlete(this.inputs);
            LocateService.listenManualChange(this.inputs);
        });
        this.listenMutations();
    }

    disableAutocomplete() {
        this.inputs.forEach((input) => {
            input.setAttribute('autocomplete', 'none');
        });
    }

    getInputs() {
        if (this.fieldsObj !== null) {
            return Object.values(this.fieldsObj);
        }

        return [];
    }

    getFieldsObj() {
        const fieldNames = {};

        this.rootNode.querySelectorAll('input[data-loqate-field]').forEach((input) => {
            if (input !== null) {
                fieldNames[input.getAttribute('name')] = input;
            }
        });
        return fieldNames;
    }

    getLoqateFieldsConfig() {
        if (this.options.loqateFieldsConfig !== undefined) {
            return this.options.loqateFieldsConfig;
        }
        const result = [];
        Object.keys(this.fieldsObj).forEach((name) => {
            const input = this.fieldsObj[name];
            const loqateField = input.getAttribute('data-loqate-field');
            let mode = '';
            const modeAttr = input.getAttribute('data-loqate-mode');
            if (modeAttr) {
                mode = modeAttr.split('|');
            }
            result.push({
                element: this.fieldsObj[name].name,
                field: loqateField.charAt(0).toUpperCase() + loqateField.slice(1),
                mode: LocateService.getMode(mode)
            });
        });
        return result;
    }

    updateCountryDropdown(countryCode) {
        if (this.countryDropdown !== null) {
            this.countryDropdown.value = countryCode;
        }
    }

    getCountryDropdown() {
        return this.rootNode.querySelector(COUNTRY_DROPDOWN_SELECTOR);
    }

    listenMutations() {
        const observer = new MutationObserver(this.onMutate);

        observer.observe(this.rootNode, {
            attributes: false,
            childList: true,
            subtree: true,
            characterData: false
        });
    }

    onMutate = () => {
        if (this.loqateControl !== null) {
            this.loqateControl.load(); // update loqate bindings
        }
    }
}

export default LoqateForm;
