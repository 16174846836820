import React from 'react';
import { ArrayHelper } from 'foundation/helpers';
import { generateBemCss } from 'project/helpers';
import { BasketCard } from 'shared/basket-card';

export class BasketProducts extends React.Component {
    render() {
        const { products } = this.props;

        if (ArrayHelper.isEmpty(products)) return null;

        const rootCss = generateBemCss('basket-products', this.props.bemList);
        const isSingle = products.length === 1 ? 'single' : '';
        const productClassName = generateBemCss('basket-products__li', [isSingle]);

        return (
            <ul className={rootCss}>
                {
                    products.map((product) => {
                        return (
                            <li className={productClassName} key={product.id}>
                                <BasketCard
                                    product={product}
                                    bemList={this.props.bemList}
                                    changeQuantity={this.props.changeQuantity}
                                    removeFromBasket={this.props.removeFromBasket}
                                    isEditable={this.props.isEditable}
                                />
                            </li>
                        );
                    })
                }
            </ul>
        );
    }
}
