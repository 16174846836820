import { isElement } from 'dom-helper';
import { dcFactory } from 'dc';

class FactoryWatcher {
    constructor(rootNode) {
        if (!isElement(rootNode)) {
            throw new Error('You must provide DOM element');
        }
        this.rootNode = rootNode;
        this.init();
    }

    init() {
        const observer = new MutationObserver(this._handleComponents);

        observer.observe(this.rootNode, {
            attributes: false,
            childList: true,
            subtree: true,
            characterData: false
        });
    }

    _handleComponents = (records) => {
        const combineElementsReduce = (result, nodes) => [...result, ...nodes];

        const recordsArray = [...records];
        const removedElements = recordsArray
            .map((record) => [...record.removedNodes])
            .reduce(combineElementsReduce, [])
            .filter(isElement);
        const addedElements = recordsArray
            .map((record) => [...record.addedNodes])
            .reduce(combineElementsReduce, [])
            .filter(isElement);

        removedElements.forEach((element) => dcFactory.destroy(element));
        addedElements.forEach((element) => dcFactory.init(element));
    };
}

export default FactoryWatcher;
