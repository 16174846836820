import React from 'react';
import { generateBemCss } from 'project/helpers';

const MIN = 1;
const MAX = 1000;

export class Quantity extends React.Component {
    constructor(props) {
        super(props);
        this.rootRef = React.createRef();
        this.inputRef = React.createRef();
        this.min = props.min !== undefined ? props.min : MIN;
        this.max = props.max !== undefined ? props.max : MAX;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    increase = () => {
        if (Number(this.props.quantity) >= this.max) return;

        this.props.onChange(this.props.quantity + 1);
    };

    decrease = () => {
        if (Number(this.props.quantity) <= this.min) return;

        this.props.onChange(this.props.quantity - 1);
    };

    onInputChange = (e) => {
        let value = Number(e.target.value);
        if (Number.isNaN(value)) {
            value = this.min;
        }
        if (value > this.max) {
            value = this.max;
        }
        if (value < this.min) {
            value = this.min;
        }

        this.props.onChange(value);
    };

    render() {
        const { childCss, quantity, name } = this.props;

        const rootCss = generateBemCss('quantity', this.props.bemList);

        return (
            <div className={`${childCss} ${rootCss}`} ref={this.rootRef}>
                <button
                    className="quantity__button quantity__button--minus"
                    type="button"
                    onClick={this.decrease}
                >
                    <span className="visually-hidden">decrease quantity</span>
                </button>
                <input
                    className="quantity__input"
                    type="number"
                    value={quantity}
                    onChange={this.onInputChange}
                    name={name}
                    aria-label='quantity'
                    disabled={this.props.disabledInput}
                />
                <span className="quantity__text">{quantity}</span>
                <button
                    className="quantity__button quantity__button--plus"
                    type="button"
                    onClick={this.increase}
                >
                    <span className="visually-hidden">increase quantity</span>
                </button>
            </div>
        );
    }
}

Quantity.defaultProps = {
    childCss: '',
    onChange: () => {},
};
