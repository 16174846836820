export const generateBemCss = (bemName, bemList) => {
    let bemCss = '';

    if (Array.isArray(bemList)) {
        bemCss = bemList
            .filter((bemModifier) => bemModifier)
            .map((bemModifier) => `${bemName}--${bemModifier}`).join(' ');
    }

    return bemCss ? `${bemName} ${bemCss}` : bemName;
};

export const getBemList = (propsBemList, stateBemList) => {
    let bemList = [];

    if (Array.isArray(propsBemList)) {
        bemList = [...propsBemList];
    }

    if (Array.isArray(stateBemList)) {
        bemList = [...bemList, ...stateBemList];
    }

    return bemList;
};

// https://github.com/facebook/react/issues/5465
export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export const getScrollTop = () => {
    return document.documentElement.scrollTop || document.body.scrollTop;
};

export const setScrollTop = (value) => {
    if (typeof value !== 'number') return;

    document.body.scrollTop = value;
    document.documentElement.scrollTop = value;
};

export const copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');

    document.body.appendChild(textarea);

    textarea.value = text;
    textarea.select();

    document.execCommand('copy');
    document.body.removeChild(textarea);
};
