import React from 'react';

const SpriteSvg = ({ childCss, iconName, ...rest }) => {
    return (
        <svg
            className={`${childCss} icon`}
            focusable="false"
            {...rest}
        >

            {/* xlink:href - Since SVG 2, the href attribute is deprecated in favor of href */}
            <use href={`#icon-${iconName}`} xlinkHref={`#icon-${iconName}`} />
        </svg>
    );
};

SpriteSvg.defaultProps = {
    childCss: '',
};

export default SpriteSvg;
