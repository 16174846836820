import axios from 'axios';
import { notificationService } from '../services';
import { notificationTypes } from '../general';

const baseApi = axios.create({
    timeout: 60000
});

baseApi.interceptors.response.use((response) => {
    const data = response.data;
    if (data.success === false || data.exceptionType !== undefined) {
        const message = data.errors !== undefined ? data.errors : data.message;
        notificationService.push({
            message,
            type: notificationTypes.ERROR,
        });
    }
    return response;
}, (error) => {
    notificationService.push({
        message: error,
        type: notificationTypes.ERROR,
    });
    return Promise.reject(error);
});
window.baseApi = baseApi;

export { baseApi };

export function isHtmlContentType(response) {
    const contentType = response.headers['content-type'];
    if (contentType) {
        return contentType.includes('text/html');
    }

    return false;
}
