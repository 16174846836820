import { BaseComponent } from 'project/general';
import { LocateService } from './loqate-service';

export default class LoqateComponent extends BaseComponent {
    static getNamespace() {
        return 'loqate';
    }


    onInit() {
        this.textInputs = Object.values(this.refs.text);
        this.readyPromise = LocateService.loadResources()
            .then(() => {
                const loqateFieldsConfig = this.getLoqateFieldsConfig();
                this.loqateControl = LocateService.createLoqateControl(loqateFieldsConfig);
                LocateService.disableAutocomlete(this.textInputs);
                LocateService.listenManualChange(this.textInputs);
                this.loqateControl.listen('populate', (loqateAddress) => {
                    this.updateCountryDropdown(loqateAddress.CountryIso2);
                });
                this.loqateControl.listen('prepopulate', (loqateAddress) => {
                    LocateService.updatePreserved(this.textInputs, loqateFieldsConfig, loqateAddress);
                });
            });
    }

    updateCountryDropdown(countryCode) {
        if (this.refs.country !== undefined) {
            this.refs.country.value = countryCode;
        }
    }

    getLoqateFieldsConfig() {
        const result = [];
        Object.keys(this.refs.text).forEach((loqateField) => {
            const element = this.refs.text[loqateField];
            if (element !== undefined) {
                const input = element;
                let mode = '';
                const modeAttr = input.getAttribute('data-loqate-mode');
                if (modeAttr) {
                    mode = modeAttr.split('|');
                }
                result.push({
                    element: element.name,
                    field: loqateField.charAt(0).toUpperCase() + loqateField.slice(1),
                    mode: LocateService.getMode(mode)
                });
            }
        });
        return result;
    }

    addListenerLoqate(eventName, handler) {
        this.readyPromise.then(() => {
            this.loqateControl.listen(eventName, (loqateAddress) => {
                handler(loqateAddress);
            });
        });
    }
}
