import { getApiUrlByName } from 'project/helpers';
import { apiNames } from 'project/general';
import { baseApi } from 'project/api';

const ADD_PRODUCT = getApiUrlByName(apiNames.ADD_PRODUCT);
const REMOVE_PRODUCT = getApiUrlByName(apiNames.REMOVE_PRODUCT);
const UPDATE_PRODUCT = getApiUrlByName(apiNames.UPDATE_PRODUCT);
const TO_CHECKOUT = getApiUrlByName(apiNames.TO_CHECKOUT);

export class BasketService {
    static addToBasket = (order) => {
        if (
            typeof order.productId !== 'number' ||
            typeof order.quantity !== 'number'
        ) {
            return Promise.reject(new Error('Invalid order props'));
        }

        return new Promise((resolve, reject) => {
            baseApi
                .post(ADD_PRODUCT, order)
                .then((res) => {
                    if (res.data.success) {
                        resolve(res.data.data);
                    } else {
                        reject(new Error(res.data.message));
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    static removeProduct = (productId) => {
        if (!productId) {
            return Promise.reject(new Error('Invalid productId'));
        }

        return new Promise((resolve, reject) => {
            baseApi
                .post(REMOVE_PRODUCT, { productId })
                .then((res) => {
                    if (res.data.success) {
                        resolve(res.data.data);
                    } else {
                        reject(new Error(res.data.message));
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    static updateProductQuantity = (model) => {
        if (!model) {
            return Promise.reject(new Error('Invalid model'));
        }

        return new Promise((resolve, reject) => {
            baseApi
                .post(UPDATE_PRODUCT, model)
                .then((res) => {
                    if (res.data.success) {
                        resolve(res.data.data);
                    } else {
                        reject(new Error(res.data.message));
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    static toCheckout = () => {
        return new Promise((resolve, reject) => {
            baseApi
                .post(TO_CHECKOUT)
                .then((res) => {
                    if (res.data.success) {
                        resolve(res.data);
                    } else {
                        reject(new Error(res.data.message));
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
