import React from 'react';
import { generateBemCss } from 'project/helpers';
import { SpriteSvg } from 'shared/sprite-svg';
import { MemberDiscount } from 'shared/member-discount';
import { EcommerceTracking } from 'project/services/';

const ProductCard = ({ model, bemList }) => {
    if (!model) return null;

    const rootCss = generateBemCss('product-card', bemList);
    return (
        <a className={`${rootCss} hover-top-transition`} href={model.url} aria-label={model.name} >
            <div className="product-card__header">
                <div className="product-card__image of-cover" dangerouslySetInnerHTML={{ __html: model.image }} />
                {
                    model.label &&
                    <div className="product-card__label">{model.label}</div>
                }
            </div>
            <div className="product-card__info">
                <div className="product-card__title">{model.name}</div>
                <div className="product-card__price">
                    <MemberDiscount model={model.prices} />
                </div>
                <div className="product-card__details">
                    <div className="product-card__detail-link">
                        <SpriteSvg iconName="arrow-right" width={13} height={10} />
                        <span className="visually-hidden">Product Details</span>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default ProductCard;
