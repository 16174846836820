import { Modal } from 'modal';
import { Observable } from 'foundation/observable';
import { BasketService, EcommerceTracking } from 'project/services';


class BasketController {
    constructor() {
        this.state = {
            basket: {},
        };


        this.modal = null;
        this.observeable = new Observable();
    }

    setBasket(basket) {
        this.state.basket = basket;
    }

    getBasket = () => {
        return this.state.basket;
    };

    addProduct = (order) => {
        if (this.modal) {
            this.modal.destroy();
        }

        return new Promise((resolve, reject) => {
            BasketService
                .addToBasket(order)
                .then((data) => {
                    if (data.modalHtml) {
                        this.modal = Modal.create(data.modalHtml);
                        this.modal.open();

                        const closeButton = this.modal.element.querySelector('[data-modal-close]');

                        if (closeButton) {
                            closeButton.addEventListener('click', () => {
                                this.hideModal();
                            });
                        }
                    }
                    if (data.basket !== undefined) {
                        this.state.basket = data.basket;
                        this.observeable.notify(this.state.basket);
                        EcommerceTracking.trackAddToBasket(order, this.state.basket);
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    removeProduct = (productId) => {
        return new Promise((resolve, reject) => {
            BasketService
                .removeProduct(productId)
                .then((data) => {
                    if (data) {
                        EcommerceTracking.trackRemoveFromBasket(productId, this.state.basket);
                        this.state.basket = data;
                        this.observeable.notify(this.state.basket);
                        resolve(data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    updateProductQuantity = (model) => {
        return new Promise((resolve, reject) => {
            BasketService
                .updateProductQuantity(model)
                .then((data) => {
                    if (data) {
                        EcommerceTracking.trackUpdateQuantity(model, this.state.basket);
                        this.state.basket = data;
                        this.observeable.notify(this.state.basket);
                        resolve(data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    toCheckout = () => {
        return new Promise((resolve, reject) => {
            BasketService
                .toCheckout()
                .then((data) => {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                        return;
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    subscribeOnUpdate = (cb) => {
        return this.observeable.subscribe(cb);
    };

    notifyBasketUpdated(basket) {
        // for cases when basket updated outside of basket controller e.g. adding event to basket
        this.observeable.notify(basket);
    }

    hideModal = () => {
        if (this.modal) {
            this.modal.close();
        }
    }
}

export const basketController = new BasketController();
