export const modeAliases = Object.freeze({
    DEFAULT: 'DEFAULT',
    POPULATE: 'POPULATE',
    SEARCH: 'SEARCH',
    COUNTRY: 'COUNTRY',
    PRESERVE: 'PRESERVE',
});

export const classes = Object.freeze({
    IS_TOUCHED: 'is-touched'
});
export const loqateFieldNames = ['Line1', 'Line2', 'Line3', 'Line4', 'City', 'Province', 'PostalCode', 'CountryName', 'Id', '{PAFDeliveryPointSuffix}'];
