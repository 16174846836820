import './scss/index.scss';
import { dcFactory } from 'dc';
import QuantityComponent from './js/quantity.component';

dcFactory.register(QuantityComponent);

export * from './js/quantity';
export {
    QuantityComponent,
};
