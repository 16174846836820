import React from 'react';
import { generateBemCss } from 'project/helpers';
import { SpriteSvg } from 'shared/sprite-svg';
import { MemberDiscount } from 'shared/member-discount';
import { Quantity } from 'shared/quantity';

export const BasketCard = (
    {
        product,
        bemList,
        isEditable,
        changeQuantity,
        removeFromBasket
    }
) => {
    if (!product) return null;

    const rootCss = generateBemCss('basket-card', bemList);

    return (
        <div className={rootCss}>
            {
                isEditable &&
                <button
                    className="basket-card__remove"
                    type="button"
                    onClick={() => removeFromBasket(product.id)}
                >
                    <SpriteSvg iconName={'cross'} width={10} height={10} />
                    <span className="visually-hidden">Remove product from the basket</span>
                </button>
            }
            <a
                className="basket-card__image of-cover"
                href={product.linkUrl}
                title={product.name}
                dangerouslySetInnerHTML={{ __html: product.image }}
            />
            <div className="basket-card__info">
                <div className="basket-card__price">
                    <MemberDiscount model={product.prices} />
                </div>
                <a className="basket-card__link" href={product.linkUrl} title={product.name}>
                    <div className="basket-card__title">{product.name}</div>
                </a>
                <div className="basket-card__quantity">
                    <div className="basket-card__quantity-title">Qty:</div>
                    {
                        isEditable ?
                            <Quantity
                                childCss={'basket-card__quantity-control'}
                                quantity={product.quantity}
                                bemList={['basket']}
                                disabledInput={true}
                                onChange={(quantity) => {
                                    changeQuantity({
                                        productId: product.id,
                                        quantity,
                                    });
                                }}
                                max={product.maxItemsInOrder}
                                min={product.minItemsInOrder}
                            /> :
                            <span className="basket-card__value">&nbsp;{product.quantity}</span>
                    }
                </div>
                <ul className="basket-card__options">
                    {
                        product.options &&
                            product.options.length > 0 &&
                            product.options.map((option, index) => {
                                return (
                                    <li className="basket-card__option" key={index}>
                                        <span className="basket-card__prop">{option.title}:</span>
                                        &nbsp;
                                        <span className="basket-card__value">{option.value}</span>
                                    </li>
                                );
                            })
                    }
                </ul>
            </div>
        </div>
    );
};

BasketCard.defaultProps = {
    isInteractive: false,
    changeQuantity: () => {},
    removeFromBasket: () => {},
};
