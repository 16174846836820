import { getApiUrlByName } from 'project/helpers';
import { apiNames } from 'project/general';
import { baseApi } from 'project/api';

const GET_SERVICE_CATEGORIES = getApiUrlByName(apiNames.GET_SERVICE_CATEGORIES);
const FIND_SERVICES_BY_NAME = getApiUrlByName(apiNames.FIND_SERVICES_BY_NAME);
const FIND_EDITABLE_SERVICES_BY_NAME = getApiUrlByName(apiNames.FIND_EDITABLE_SERVICES_BY_NAME);
const FIND_NON_EDITABLE_SERVICES_BY_NAME = getApiUrlByName(apiNames.FIND_NON_EDITABLE_SERVICES_BY_NAME);
const GET_SERVICE_LISTING_REQUEST = getApiUrlByName(apiNames.GET_SERVICE_LISTING_REQUEST);
const GET_ASD_SELF_SERVICE_USER_CONTEXT = getApiUrlByName(apiNames.GET_ASD_SELF_SERVICE_USER_CONTEXT);
export class ServiceEditorService {

    static getServiceCategoryOptions = () => {

        return baseApi
            .get(GET_SERVICE_CATEGORIES)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });
            
    }

    static findServicesByName = (searchText) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(FIND_SERVICES_BY_NAME, "\"" + searchText + "\"", config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });

    }

    static findEditableServicesByName = (searchText) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(FIND_EDITABLE_SERVICES_BY_NAME, "\"" + searchText + "\"", config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });

    }

    static findNonEditableServicesByName = (searchText) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(FIND_NON_EDITABLE_SERVICES_BY_NAME, "\"" + searchText + "\"", config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });

    }

    static getServiceListingRequest = (nodeGuid) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(GET_SERVICE_LISTING_REQUEST, "\"" + nodeGuid + "\"", config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });

    }

    static getAsdSelfServiceUserContext = (nodeGuid) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(GET_ASD_SELF_SERVICE_USER_CONTEXT, "\"" + nodeGuid + "\"", config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });
    }

}
