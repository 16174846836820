import { dcFactory } from 'dc';

class App {
    constructor() {
        this.dcFactory = dcFactory;
        this.config = window.appConfig || {};
        this.state = window.appState || {};
    }

    init() {
        this.dcFactory.init();
    }

    getConfig(property, defaultValue = undefined) {
        return property in this.config ? this.config[property] : defaultValue;
    }
}

const instance = new App();
export default instance;
