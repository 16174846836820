export default class ArrayHelper {
    static findIndexByProp(array, prop, value) {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][prop] === value) {
                return i;
            }
        }

        return -1;
    }

    static isEmpty(array) {
        return !Array.isArray(array) || !array.length;
    }
}
