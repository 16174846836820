export class Observable {
    constructor() {
        this.observers = [];
    }

    destroy() {
        this.state = null;
        this.observers = null;
    }

    subscribe = (cb) => {
        if (typeof cb !== 'function') {
            throw new Error('Callback must be function');
        }

        this.observers.push(cb);

        return () => {
            this.observers = this.observers.filter((observer) =>
                observer !== cb);
        };
    };

    notify = (state) => {
        this.observers.forEach((observer) => observer(state));
    }
}
