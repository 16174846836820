import { Observable } from 'foundation/observable';
import GtmService from './gtm';
import { baseApi } from 'project/api';

import { notificationService } from 'project/services';
import { notificationTypes } from 'project/general';
import { getApiUrlByName } from 'project/helpers';
import { apiNames } from 'project/general';
const SET_COOKIE_LEVEL_PATH = getApiUrlByName(apiNames.SET_COOKIE_LEVEL);
const GET_COOKIE_LEVEL_PATH = getApiUrlByName(apiNames.GET_COOKIE_LEVEL);

const cookieLevels = Object.freeze({
    NECESSARY: -100,
    FUNCTIONAL: 0,
    ANALYTICS: 200,
    THIRD_PARTY: 1000
});

export class CookieService {

    static init = () => {
        if (this.observeableSettings == undefined) {
            this.observeableSettings = new Observable();
            this.observeableLevel = new Observable();
            this.currentLevel = this.getCookieLevel();
        }
    }

    static loadCookieLevel = () => {
        return baseApi.get(GET_COOKIE_LEVEL_PATH);
    }

    static getCookieLevel = () => {
        this.init();
        if (this.currentLevel === undefined && baseApi != undefined) {
            this.loadCookieLevel().then((result) => {
                this.currentLevel = result.data.data.userCookieLevel;
                this.setLocalStorage(this.currentLevel);
            });
        }
        return this.currentLevel;
    }

    static setLocalStorage = (cookieLevel) => {
        localStorage.setItem('necessary_cookies', cookieLevel >= cookieLevels.NECESSARY);
        localStorage.setItem('functional_cookies', cookieLevel >= cookieLevels.FUNCTIONAL);
        localStorage.setItem('analytics_cookies', cookieLevel >= cookieLevels.ANALYTICS);
        localStorage.setItem('third-party_cookies', cookieLevel >= cookieLevels.THIRD_PARTY);
    }

    static setCookieLevel = (level) => {
        this.init();
        this.currentLevel = level;
        this.observeableLevel.notify(this.currentLevel);
    }

    static subscribeOnCookieLevelChanged = (cb) => {
        this.init();
        return this.observeableLevel.subscribe(cb);
    }

    //  Saves the cookie level
    static saveCookieLevel = (cookieLevel) => {
        this.init();
        this.currentLevel = cookieLevel;
        this.observeableLevel.notify(this.currentLevel)
        this.observeableSettings.notify(cookieLevel);
        const config = { headers: { 'Content-Type': 'application/json' } };
        baseApi.post(SET_COOKIE_LEVEL_PATH, "\"" + cookieLevel + "\"", config);
        this.sendGtmEvent();
        notificationService.push({
            message: 'Cookie settings saved',
            type: notificationTypes.SUCCESS,
        });
        localStorage.setItem('necessary_cookies', this.currentLevel >= cookieLevels.NECESSARY);
        localStorage.setItem('functional_cookies', this.currentLevel >= cookieLevels.FUNCTIONAL);
        localStorage.setItem('analytics_cookies', this.currentLevel >= cookieLevels.ANALYTICS);
        localStorage.setItem('third-party_cookies', this.currentLevel >= cookieLevels.THIRD_PARTY);
    }

    static subscribeOnUpdateSettings = (cb) => {
        this.init();
        return this.observeableSettings.subscribe(cb);
    }

    static sendGtmEvent = () => {
        this.init();
        if (this.currentLevel === cookieLevels.THIRD_PARTY) {
            GtmService.pushEvent('Third Party Cookies', 'Trigger', 'Analytics Success', 'ThirdPartyCookies');
            window['ga-disable-UA-5637156-3'] = false;
        }
        if (this.currentLevel === cookieLevels.ANALYTICS) {
            GtmService.pushEvent('Analytical Cookies', 'Trigger', 'Analytics Success', 'AnalyticalCookies');
            window['ga-disable-UA-5637156-3'] = false;
        }
        if (this.currentLevel === cookieLevels.FUNCTIONAL) {
            GtmService.pushEvent('Functional Cookies', 'Trigger', 'Analytics Disabled', 'FunctionalCookies');
            window['ga-disable-UA-5637156-3'] = true;
        }
        if (this.currentLevel === cookieLevels.NECESSARY) {
            GtmService.pushEvent('Necessary Cookies', 'Trigger', 'Analytics Disabled', 'NecessaryCookies');
            window['ga-disable-UA-5637156-3'] = true;
        }
    }
}
