import { app } from 'general';

class GtmService {
    _send(event, data) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({ event, ...data });
        }
    }

    pushEvent(category, action, label, event = 'eventTrack') {
        this._send(event, {
            eventCategory: category,
            eventAction: action,
            eventLabel: this._swapVariables(label)
        });
    }

    pushData(data) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push(data);
        }
    }

    _swapVariables(string) {
        return string.replace(/\$page/gi, app.getConfig('page'));
    }
}

export default new GtmService();
