import { DcBaseComponent } from 'dc';
import { buttonText } from './enums';

const MAX_LENGTH = 200;

class ReadMoreComponent extends DcBaseComponent {
    static getNamespace() {
        return 'read-more';
    }

    static getRequiredRefs() {
        return ['text', 'button', 'textButton'];
    }

    onInit() {
        const lengthText = this.getTextLength();

        if (lengthText && lengthText > MAX_LENGTH) {
            this.refs.text.classList.add('is-short');
            this.refs.button.classList.remove('is-hidden');
            this.refs.button.setAttribute('aria-hidden', 'false');

            this.addListener(this.refs.button, 'click', this.onClick);
        }
    }

    getTextLength() {
        if (!this.refs.text.innerText.length) return null;

        return this.refs.text.innerText.length;
    }

    onClick = () => {
        this.refs.text.classList.toggle('is-short');

        if (this.refs.textButton.innerText === buttonText.MORE) {
            this.refs.textButton.innerText = buttonText.LESS;
            this.refs.button.classList.add('less');
        } else {
            this.refs.textButton.innerText = buttonText.MORE;
            this.refs.button.classList.remove('less');
        }
    }
}

export default ReadMoreComponent;
