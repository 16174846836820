/* eslint-disable no-bitwise */
import { resourceLoader } from 'project/resource-loader';
import { app } from 'general';
import { ArrayHelper } from 'foundation/helpers';
import {
    modeAliases,
    classes
} from './enums';

class LocateService {
    constructor() {
        this.controls = {};
    }

    static loadResources() {
        return resourceLoader.load(app.getConfig('loqate').resources);
    }

    static createLoqateControl(fields) {
        return new window.pca.Address(fields, { key: app.getConfig('loqate').apiKey });
    }

    static getMode(modeAlias) {
        if (modeAlias == '') return window.pca.fieldMode.NONE;
        if (!Array.isArray(modeAlias)) {
            return window.pca.fieldMode[modeAlias];
        }
        return modeAlias.reduce((accumulator, value) => {
            const x = (modeAlias == '') ? '' : window.pca.fieldMode[value];
            return accumulator | x;
        }, 0);
    }

    static getDefaultModConfig() {
        return {
            Line1: modeAliases.DEFAULT,
            Line2: [modeAliases.POPULATE, modeAliases.PRESERVE],
            Line3: [modeAliases.POPULATE, modeAliases.PRESERVE],
            Line4: [modeAliases.POPULATE, modeAliases.PRESERVE],
            City: [modeAliases.POPULATE, modeAliases.PRESERVE],
            Province: [modeAliases.POPULATE, modeAliases.PRESERVE],
            PostalCode: modeAliases.DEFAULT,
            CountryName: modeAliases.COUNTRY
        };
    }

    static disableAutocomlete(elements) {
        setTimeout(() => {
            elements.forEach((element) => {
                element.setAttribute('autocomplete', 'none');
            });
        }, 300);
    }

    static listenManualChange(elements) {
        elements.forEach((element) => {
            element.addEventListener('change', () => {
                element.classList.add(classes.IS_TOUCHED);
            });
        });
    }

    static updatePreserved(inputs, loqateFieldsConfig, address) {
        inputs.forEach((input) => {
            const name = input.name;
            if (input.classList.contains(classes.IS_TOUCHED) && input.value !== '') {
                return; // continue if field was not changed manually and not empty
            }


            const fieldConfig = loqateFieldsConfig[ArrayHelper.findIndexByProp(loqateFieldsConfig, 'element', name)];
            if (fieldConfig.mode & window.pca.fieldMode[modeAliases.PRESERVE]) {
                // if the field mode has 'Preserve' flag
                let key = fieldConfig.field;
                let value = address[key];
                if (!value) {
                    key = key.charAt(0).toUpperCase() + key.slice(1);
                    value = address[key];
                }
                if (value) {
                    input.value = value;
                }
            }
        });
    }
}

export {
    LocateService
};
