import { DcBaseComponent } from 'dc';

const getContentAsObject = (element) => {
    let result = {};
    const content = element.innerHTML;

    if (content) {
        try {
            result = JSON.parse(content);
        } catch (error) {
            console.error('Unable to parse content of element:', element);
            throw error;
        }
    }

    return result;
};

export class BaseComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.initData = {};

        if (this.refs.data) {
            this.initData = getContentAsObject(this.refs.data);
        }
    }
}
